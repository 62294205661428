import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, LayerGroup, Tooltip, Polyline } from 'react-leaflet';
import { createBuildingSVG4 } from '../util/building';
import { locations } from '../util/locations';
import logo from '../logo_small.svg'; // Adjust the path as needed

const windowColor = 'lightblue';

const createBuildingIcon = (baseColor: string, windowColor: string) => {
  return L.divIcon({
    className: '',
    html: createBuildingSVG4(baseColor),
    iconSize: [32, 32],
    iconAnchor: [16, 16],
  });
}

// Add the green icons
const okMarkers: L.Marker<Location>[] = locations.map(loc => {
    const marker = L.marker([loc.lat, loc.lng], { icon: createBuildingIcon(loc.color, windowColor) })
    marker.text = loc.text
    marker.img = loc.img
    marker.issue = loc.issue
    marker.color = loc.color
    return marker
});

const logoIcon = L.icon({
  iconUrl: logo,
  
  iconSize: [120, 580], // Adjust the size as needed
  iconAnchor: [60, 290], // Center the icon
});

const MagnifyerMap: React.FC = () => {
  const [coloredMarkers, setColoredMarkers] = useState<number[]>([]);
  const [linePosition, setLinePosition] = useState<number>(0);
  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    const animationDuration = 6000; // 10 seconds (as previously set)
    const startTime = Date.now();
    let logoMarker: L.Marker | null = null;

    const animate = () => {
      const elapsed = Date.now() - startTime;
      const progress = Math.min(elapsed / animationDuration, 1);
      
      if (mapRef.current) {
        const bounds = mapRef.current.getBounds();
        const leftLng = bounds.getWest();
        const rightLng = bounds.getEast();
        const currentLng = leftLng + (rightLng - leftLng) * progress;
        
        setLinePosition(currentLng);
        
        // Update logo position
        const centerLat = (bounds.getNorth() + bounds.getSouth()) / 2;
        if (!logoMarker) {
          logoMarker = L.marker([centerLat, currentLng], { icon: logoIcon, zIndexOffset:100 }).addTo(mapRef.current);
        } else {
          logoMarker.setLatLng([centerLat, currentLng]);
        }
        
        const newColoredMarkers = okMarkers
          .map((marker, index) => ({ marker, index }))
          .filter(({ marker }) => marker.getLatLng().lng <= currentLng)
          .map(({ index }) => index);
        
        setColoredMarkers(newColoredMarkers);
      }

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        // Remove the logo marker when animation is complete
        if (logoMarker && mapRef.current) {
          mapRef.current.removeLayer(logoMarker);
        }
      }
    };

    requestAnimationFrame(animate);

    return () => {
      // Cleanup function to remove the logo marker if component unmounts
      if (logoMarker && mapRef.current) {
        mapRef.current.removeLayer(logoMarker);
      }
    };
  }, []);

  return (
    <MapContainer
      center={[51.405, -0.19]}
      zoom={8}
      zoomControl={false}
      scrollWheelZoom={false}
      doubleClickZoom={false}
      boxZoom={false}
      keyboard={false}
      dragging={false}
      tap={false}
      touchZoom={false}
      zoomSnap={0}
      zoomDelta={0}
      inertia={false}
      style={{ height: '100vh', width: '100%' }}
      ref={(map) => { mapRef.current = map; }}
    >
      <TileLayer
        url='https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXNtdWVsbGUiLCJhIjoiY2pwMml0bTI1MDduaTN2cnd4Z2dqZWVpdCJ9.ycIptPf7zekWNCNi-bgY7A'
        opacity={0.4}
        id='mapbox/light-v10'
       
      />
      
      <LayerGroup>
     
        {okMarkers.map((marker, index) => (
          <Marker 
            key={`ok-${index}`}
            position={marker.getLatLng()} 
            icon={coloredMarkers.includes(index) ? createBuildingIcon(marker.color, windowColor) : createBuildingIcon('#808080', 'lightgray')}
          >
            <Tooltip>{marker.issue} (click for details)</Tooltip>
            <Popup>
              {marker.text}
              <img width="300px" src={marker.img} alt="Location" />
            </Popup>
          </Marker>
        ))}
      </LayerGroup>
      
     
    </MapContainer>
  );
};

export default MagnifyerMap;
