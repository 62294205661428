import React, { useState, useEffect } from "react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
 
interface ContactFormProps {
  onClose: () => void;
}

interface ContactFormData {
  firstName: string;
  lastName: string;
  organization: string;
  role: string;
  email: string;
  phone: string;
  background: string;
  captchaToken: string | null;
}

const ContactFormInner: React.FC<ContactFormProps> = ({ onClose }) => {
  const [formData, setFormData] = useState<ContactFormData>({
    firstName: '',
    lastName: '',
    organization: '',
    role: '',
    email: '',
    phone: '',
    background: '',
    captchaToken: null,
  });

  const [formErrors, setFormErrors] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCaptchaChange = (token: string | null) => {
    setFormData({
      ...formData,
      captchaToken: token,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (!executeRecaptcha) {
      setFormErrors("reCAPTCHA not loaded");
      setIsLoading(false);
      return;
    }

    try {
      let token = await executeRecaptcha('contact_form');
      if (!token) {
        // Retry once if the first attempt fails
        console.log("First reCAPTCHA attempt failed, retrying...");
        token = await executeRecaptcha('contact_form');
      }
      if (!token) {
        setIsLoading(false);
        setFormErrors("Failed to execute reCAPTCHA. Please try again.");
        return;
      }
      setFormData({
        ...formData,
        captchaToken: token,
      });

      // Send the form data to the backend
      const response = await fetch(`${process.env.REACT_APP_API_URL}/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...formData, captchaToken: token}),
      });

      if (response.ok) {
        setFormData(
          { firstName: '', lastName: '', organization: '', role: '', email: '', phone: '', background: '', captchaToken: null }
        );
        setIsLoading(false);
        onClose();
      } else {
        const errorData = await response.json();
        setIsLoading(false);
        setFormErrors(errorData.message || "Something went wrong");
      }
    } catch (error) {
      setIsLoading(false);
      setFormErrors("An error occurred. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
	      <label htmlFor="firstName">First Name: </label>
	      <input id="firstName" name="firstName" type="text" required onChange={handleInputChange} value={formData.firstName}/>
	      <label htmlFor="lastName">Last Name: </label>
	      <input id="lastName" name="lastName" type="text" required onChange={handleInputChange} value={formData.lastName}/>
	      <label htmlFor="organization">Organization: </label>
	      <input id="organization" name="organization" type="text" required onChange={handleInputChange} value={formData.organization}/> 
	      <label htmlFor="role">Title: </label>
	      <input id="role" name="role" type="text" required onChange={handleInputChange} value={formData.role}  />
	      <label htmlFor="email">Email Address: </label>
	      <input id="email" name="email" type="email" required onChange={handleInputChange} value={formData.email}   />
	      <label htmlFor="phone">Phone: </label>
	      <input id="phone" name="phone" type="text" onChange={handleInputChange} value={formData.phone}  />
	      <label htmlFor="background">Additional background information: </label>
	      <textarea id="background" name="background" onChange={handleInputChange} value={formData.background}></textarea>
	      
       
         </div>
    <div className="modal-footer">
    {formErrors && <div className="-messageerror">{formErrors}</div>}
       <button type="submit" disabled={isLoading} className="btn btn-primary">
         {isLoading ? <span className="loading-icon"></span> : 'Send'}
       </button>
  </div>
    
    </form>
  );
};

const ContactForm: React.FC<ContactFormProps> = (props) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LfYnccZAAAAACct73yF-UxYVL8KkC8tq2MA5DJW">
    <ContactFormInner {...props} />
  </GoogleReCaptchaProvider>
);

export default ContactForm;
