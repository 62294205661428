export function generateBuildingSVG(
    width: number,
    height: number,
    windowRows: number,
    windowColumns: number,
    windowSpacing: number = 3
  ){
    const buildingWidth = Math.max(width, 100);  // Ensure minimum width
    const buildingHeight = Math.max(height, 100);  // Ensure minimum height
    const roofHeight = buildingHeight ;
    const wallWidth = buildingWidth ;
    const wallHeight = buildingHeight;
  
    // Calculate window dimensions
    let windowWidth = Math.max(1, (wallWidth - (windowColumns + 1) * windowSpacing) / windowColumns);
    let windowHeight = Math.max(1, (wallHeight - (windowRows + 1) * windowSpacing) / windowRows);
  
    // Adjust number of windows if necessary
    const actualColumns = Math.min(windowColumns, Math.floor((wallWidth - windowSpacing) / (windowWidth + windowSpacing)));
    const actualRows = Math.min(windowRows, Math.floor((wallHeight - windowSpacing) / (windowHeight + windowSpacing)));
  
    // Recalculate window dimensions
    windowWidth = (wallWidth - (actualColumns + 1) * windowSpacing) / actualColumns;
    windowHeight = (wallHeight - (actualRows + 1) * windowSpacing) / actualRows;
  
    const windowsGroup = Array.from({ length: actualRows }, (_, row) =>
      Array.from({ length: actualColumns }, (_, col) => `
        <rect
          x="${50 + windowSpacing + (windowWidth + windowSpacing) * col}"
          y="${50 + windowSpacing + (windowHeight + windowSpacing) * row}"
          width="${windowWidth}"
          height="${windowHeight}"
          fill="#4320b5"
          stroke="#000000"
          stroke-width="0"
        />`
      ).join('')
    ).join('');
  
  
    const svg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="${buildingWidth}" height="${buildingHeight}" viewBox="0 0 200 200">
    <defs>
      <g id="windows">
        ${windowsGroup}
      </g>
    </defs>
  
    <!-- Front face of the building -->
    <g>
      <!-- Wall of the building -->
      <rect x="50" y="50" width="${wallWidth}" height="${wallHeight}" fill="#3ab73e" stroke="#000000" stroke-width="0"/>
      <!-- Windows on the building -->
      <use xlink:href="#windows" />
    </g>
  
    <!-- Roof of the building -->
    <rect
      x="50"
      y="50"
      width="${wallWidth}"
      height="${roofHeight}"
      fill="#8bc34a"
      stroke="#000000"
      stroke-width="0"
      transform="skewX(-60) scale(1 0.2) translate(${buildingWidth * 0.865}, 0)"/>

      />
  
    <!-- Side wall of the building -->
    <g transform="skewY(-30) scale(.3671 1) translate(${buildingWidth * 6.68}, ${buildingHeight * 0.865})">
      <!-- Wall -->
      <rect x="50" y="50" width="${wallWidth}" height="${wallHeight}" fill="#5e8c31" stroke="#000000" stroke-width="0"/>
      <!-- Windows on the building -->
      <use xlink:href="#windows" />
    </g>
  </svg>
    `.trim();
   return svg
  }
  export   const building_svg = (baseColor:string, windowColor:string)=>`
  <svg width="40" height="60" viewBox="0 0 200 200"
xmlns="http://www.w3.org/2000/svg" version="1.1">
<title>Example filters01.svg - introducing filter effects</title>
<desc>An example which combines multiple filter primitives
   to produce a 3D lighting effect on a graphic consisting
   of the string "SVG" sitting on top of oval filled in red
   and surrounded by an oval outlined in red.</desc>
<defs>
<filter id="MyFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="200" height="120">
 <feGaussianBlur in="SourceAlpha" stdDeviation="4" result="blur"/>
 <feOffset in="blur" dx="4" dy="4" result="offsetBlur"/>
 <feSpecularLighting in="blur" surfaceScale="5" specularConstant=".75" 
                     specularExponent="20" lighting-color="#bbbbbb"  
                     result="specOut">
   <fePointLight x="-5000" y="-10000" z="20000"/>
 </feSpecularLighting>
 <feComposite in="specOut" in2="SourceAlpha" operator="in" result="specOut"/>
 <feComposite in="SourceGraphic" in2="specOut" operator="arithmetic" 
              k1="0" k2="1" k3="1" k4="0" result="litPaint"/>
 <feMerge>
   <feMergeNode in="offsetBlur"/>
   <feMergeNode in="litPaint"/>
 </feMerge>
</filter>
</defs>
<g>
 <g>
 <rect x="1" y="1" width="100" height="120" fill="${baseColor}" stroke="none" stroke-width="5px rx="2" ry="2"/>
 <rect x="20" y="20" width="30" height="20" fill="${windowColor}" stroke="none" stroke-width="5px rx="2" ry="2"/>
 <rect x="56" y="20" width="30" height="20" fill="${windowColor}" stroke="none" stroke-width="5px rx="2" ry="2"/>
 <rect x="20" y="45" width="30" height="20" fill="${windowColor}" stroke="none" stroke-width="5px rx="2" ry="2"/>
 <rect x="56" y="45" width="30" height="20" fill="${windowColor}" stroke="none" stroke-width="5px rx="2" ry="2"/>
 <rect x="20" y="70" width="30" height="20" fill="${windowColor}" stroke="none" stroke-width="5px rx="2" ry="2"/>
 <rect x="56" y="70" width="30" height="40" fill="${windowColor}" stroke="none" stroke-width="5px rx="2" ry="2"/>
</g>

  </g>
</svg>
`

const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.min(255, Math.max(0, R + Math.round((255 * percent) / 100)));
  G = Math.min(255, Math.max(0, G + Math.round((255 * percent) / 100)));
  B = Math.min(255, Math.max(0, B + Math.round((255 * percent) / 100)));

  return `#${R.toString(16).padStart(2, '0')}${G.toString(16).padStart(2, '0')}${B.toString(16).padStart(2, '0')}`;
}

export function building_svg2(baseColor: string): string {
  return `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="30" height="30">
   <defs>
    <filter id="drop-shadow">
      <feGaussianBlur in="SourceAlpha" stdDeviation="2"/>
      <feOffset dx="2" dy="2" result="offsetblur"/>
      <feFlood flood-color="rgba(0,0,0,0.5)"/>
      <feComposite in2="offsetblur" operator="in"/>
      <feMerge>
        <feMergeNode/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
  </defs>  
  <!-- Building Shape -->
    <rect x="20" y="20" width="60" height="60" rx="5" fill="url(#grad1${baseColor})" filter="url(#drop-shadow)"/>
    
    <!-- Windows (3x3 grid) -->
    <rect x="22" y="25" width="18" height="15" fill="${adjustColor(baseColor, 50)}" />
    <rect x="41" y="25" width="18" height="15" fill="${adjustColor(baseColor, 50)}" />
    <rect x="60" y="25" width="18" height="15" fill="${adjustColor(baseColor, 50)}" />
    
    <rect x="22" y="42" width="18" height="15" fill="${adjustColor(baseColor, 50)}" />
    <rect x="41" y="42" width="18" height="15" fill="${adjustColor(baseColor, 50)}" />
    <rect x="60" y="42" width="18" height="15" fill="${adjustColor(baseColor, 50)}" />
    
    <rect x="22" y="59" width="18" height="15" fill="${adjustColor(baseColor, 50)}" />
    <rect x="41" y="59" width="18" height="15" fill="${adjustColor(baseColor, 50)}" />
    <rect x="60" y="59" width="18" height="15" fill="${adjustColor(baseColor, 50)}" />
    
   
    <!-- Gradient -->
    <defs>
      <linearGradient id="grad1${baseColor}" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style="stop-color: ${baseColor}; stop-opacity:1" />
        <stop offset="100%" style="stop-color: ${adjustColor(baseColor, -40)}; stop-opacity:1" />
      </linearGradient>
    </defs>

    <!-- Locator Pin -->
    <circle cx="50" cy="90" r="8" fill="${adjustColor(baseColor, -20)}" filter="url(#drop-shadow)"/>
  </svg>
  `;
}

// Helper function to adjust color brightness (lighter or darker)
function adjustColor(color: string, amount: number): string {
  let usePound = false;

  if (color[0] == "#") {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color, 16);
  let r = (num >> 16) + amount;
  let g = ((num >> 8) & 0x00ff) + amount;
  let b = (num & 0x0000ff) + amount;

  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  return (usePound ? "#" : "") + ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0");
}

 

export function createBuildingSvg3 (baseColor: string) {
  return `
     <svg width="40" height="60" viewBox="0 0 200 200"
   xmlns="http://www.w3.org/2000/svg" version="1.1">
<title>Example filters01.svg - introducing filter effects</title>
<desc>An example which combines multiple filter primitives
      to produce a 3D lighting effect on a graphic consisting
      of the string "SVG" sitting on top of oval filled in red
      and surrounded by an oval outlined in red.</desc>
<defs>
  <filter id="MyFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="200" height="120">
    <feGaussianBlur in="SourceAlpha" stdDeviation="4" result="blur"/>
    <feOffset in="blur" dx="4" dy="4" result="offsetBlur"/>
    <feSpecularLighting in="blur" surfaceScale="5" specularConstant=".75" 
                        specularExponent="20" lighting-color="#bbbbbb"  
                        result="specOut">
      <fePointLight x="-5000" y="-10000" z="20000"/>
    </feSpecularLighting>
    <feComposite in="specOut" in2="SourceAlpha" operator="in" result="specOut"/>
    <feComposite in="SourceGraphic" in2="specOut" operator="arithmetic" 
                 k1="0" k2="1" k3="1" k4="0" result="litPaint"/>
    <feMerge>
      <feMergeNode in="offsetBlur"/>
      <feMergeNode in="litPaint"/>
    </feMerge>
  </filter>
</defs>
<g filter="url(#MyFilter)" >
  <g>
    <rect x="1" y="1" width="100" height="120" fill="${baseColor}" stroke="none" stroke-width="5px rx="2" ry="2"/>
    <rect x="20" y="20" width="30" height="20" fill="blue" stroke="none" stroke-width="5px rx="2" ry="2"/>
    <rect x="56" y="20" width="30" height="20" fill="blue" stroke="none" stroke-width="5px rx="2" ry="2"/>
    <rect x="20" y="45" width="30" height="20" fill="blue" stroke="none" stroke-width="5px rx="2" ry="2"/>
    <rect x="56" y="45" width="30" height="20" fill="blue" stroke="none" stroke-width="5px rx="2" ry="2"/>
    <rect x="20" y="70" width="30" height="20" fill="blue" stroke="none" stroke-width="5px rx="2" ry="2"/>
    <rect x="56" y="70" width="30" height="40" fill="#0a0a0a" stroke="none" stroke-width="5px rx="2" ry="2"/>
  </g>

     </g>
</svg>
  ` 
}

export function createBuildingSVG4(baseColor: string) {
  // Generate the SVG as a string
  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" id="building-${baseColor}"">
        <defs>
            <!-- Base color -->
            <linearGradient id="baseColor-${baseColor}" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style="stop-color:${baseColor};stop-opacity:1" />
                <stop offset="100%" style="stop-color:${shadeColor(baseColor, -20)};stop-opacity:1" />
            </linearGradient>

            <!-- Lighter shade -->
            <linearGradient id="lighterColor-${baseColor}" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style="stop-color:${shadeColor(baseColor, 20)};stop-opacity:1" />
                <stop offset="100%" style="stop-color:${shadeColor(baseColor, 10)};stop-opacity:1" />
            </linearGradient>

            <!-- Darker shade -->
            <linearGradient id="darkerColor-${baseColor}" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style="stop-color:${shadeColor(baseColor, -30)};stop-opacity:1" />
                <stop offset="100%" style="stop-color:${shadeColor(baseColor, -40)};stop-opacity:1" />
            </linearGradient>
        </defs>

        <path fill="url(#baseColor-${baseColor})" d="M6 25.029V7.162l10-5.77 10 5.77v17.867l-10-5.455z"></path>
        <path fill="url(#lighterColor-${baseColor})" d="m26 7.173-10-5.77v-1l10 5.77z"></path>
        <path fill="url(#lighterColor-${baseColor})" d="m6 7.173 10-5.77v-1L6 6.174zM26 6.17l-10 5.77v1l10-5.77z"></path>
        <path fill="url(#lighterColor-${baseColor})" d="m26 7.167-10 5.774V31l10-5.77z"></path>
        <path fill="url(#darkerColor-${baseColor})" d="m6 7.167 10 5.774V31l-10-5.77z"></path>
        <path fill="url(#lighterColor-${baseColor})" d="m6 6.17 10 5.769v1L6 7.169z"></path>
        <path fill="#e0ecf4" d="m19 12.384-2 1.155v3.012l2-1.154zm3-1.729-2 1.155v3.012l2-1.154zm3-1.73-2 1.154v3.012l2-1.154zm-6 7.589-2 1.154v3.012l2-1.154zm3-1.73-2 1.155v3.012l2-1.154zm3-1.73-2 1.155v3.012l2-1.154zm-6 7.589-2 1.155v3.012l2-1.154zm3-1.729-2 1.155v3.012l2-1.154zm3-1.73-2 1.154v3.012l2-1.154zm-6 7.588-2 1.155v3.012l2-1.154zm3-1.729-2 1.155v3.012l2-1.154zm3-1.73-2 1.154v3.012l2-1.154z"></path>
        <path fill="#3a90cd" d="m13 12.384 2 1.155v3.012l-2-1.154zm-3-1.729 2 1.155v3.012l-2-1.154zm-3-1.73 2 1.154v3.012l-2-1.154zm6 7.589 2 1.154v3.012l-2-1.154zm-3-1.73 2 1.155v3.012l-2-1.154zm-3-1.73 2 1.155v3.012l-2-1.154zm6 7.589 2 1.155v3.012l-2-1.154zm-3-1.729 2 1.155v3.012l-2-1.154zm-3-1.73 2 1.154v3.012l-2-1.154zm6 7.588 2 1.155v3.012l-2-1.154zm-3-1.729 2 1.155v3.012l-2-1.154zm-3-1.73 2 1.154v3.012l-2-1.154z"></path>
         <g transform="rotate(45) translate(-2,-18) skewX(-14) skewY(-15)">

  <text x="20.8" y="20.7" font-size="10" text-anchor="middle" fill="black" > </text>
    </svg>
    `

  
}

