interface location {
    lat:number;
    lng:number;
    issue:string;
    text:string;
    img:string
    color:string
}

// Extend L.Marker interface
declare module 'leaflet' {
    interface Marker {
      issue: string; 
      text:string;
      img:string
      color:string
    }
}
const okColor='#3ab73e'
const warnColor='#ffff0c'
const infoColor='#fbb83b'
const problemColor='#f02b49'
export const locations:location[] = [
    {
      "color": infoColor,
      "img": "badclock1.png",
      "lat": 53.37827,
      "lng": -1.465928,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock2.png",
      "lat": 53.382244,
      "lng": -1.465683,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock3.png",
      "lat": 52.24661,
      "lng": 0.16449,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock1.png",
      "lat": 53.374462,
      "lng": -1.491047,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock2.png",
      "lat": 55.021637,
      "lng": -1.484327,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock3.png",
      "lat": 55.03621,
      "lng": -1.471106,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock1.png",
      "lat": 55.03621,
      "lng": -1.471106,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock2.png",
      "lat": 55.032032,
      "lng": -1.466499,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock3.png",
      "lat": 55.055824,
      "lng": -1.580959,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock1.png",
      "lat": 55.02918,
      "lng": -1.572899,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock2.png",
      "lat": 55.013947,
      "lng": -1.595657,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock3.png",
      "lat": 51.85836,
      "lng": -4.328713,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock1.png",
      "lat": 50.37544,
      "lng": -4.13794,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": infoColor,
      "img": "badclock2.png",
      "lat": 51.48305,
      "lng": -2.541482,
      "issue": "Time clock Problem",
      "text": "Time clock is not in sync with opening hours"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 51.288822,
      "lng": 1.094313,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 51.288822,
      "lng": 1.094313,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 51.288822,
      "lng": 1.094313,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 51.288822,
      "lng": 1.094313,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 55.03095,
      "lng": -1.57607,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 55.0083,
      "lng": -1.442969,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 55.002296,
      "lng": -1.458623,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 55.002296,
      "lng": -1.458623,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 55.01488,
      "lng": -1.512562,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 55.039085,
      "lng": -1.572182,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 55.03752,
      "lng": -1.504761,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 51.375675,
      "lng": -0.275368,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 52.402958,
      "lng": -1.504161,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 52.97517,
      "lng": -1.08337,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 53.209538,
      "lng": -1.147884,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 52.92106,
      "lng": -1.215758,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 52.958694,
      "lng": -1.227401,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 52.202312,
      "lng": 0.11934,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 54.768112,
      "lng": -1.571874,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 54.763058,
      "lng": -1.581438,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 51.06,
      "lng": -1.32674,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 51.06,
      "lng": -1.32674,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 51.329838,
      "lng": -0.265045,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 51.45847,
      "lng": -2.602074,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 53.748,
      "lng": -0.33376,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 51.51948,
      "lng": -0.129063,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 53.226795,
      "lng": -4.137423,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 51.623558,
      "lng": -3.943862,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 51.623558,
      "lng": -3.943862,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 50.726757,
      "lng": -3.535014,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 50.227318,
      "lng": -5.271827,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 51.498917,
      "lng": -3.198212,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 50.73405,
      "lng": -3.544026,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 50.73405,
      "lng": -3.544026,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 50.73405,
      "lng": -3.544026,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 50.73405,
      "lng": -3.544026,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 50.73405,
      "lng": -3.544026,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 50.740616,
      "lng": -3.544312,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste3.png",
      "lat": 50.723106,
      "lng": -3.518531,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste1.png",
      "lat": 50.417213,
      "lng": -4.110159,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "goodwaste2.png",
      "lat": 51.76227,
      "lng": -1.262213,
      "issue": "This site is performing as it should be",
      "text": "This site is performing as it should be"
    },
    {
      "color": okColor,
      "img": "good3.png",
      "lat": 51.76227,
      "lng": -1.262213,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good1.png",
      "lat": 52.96752,
      "lng": -1.08064,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good2.png",
      "lat": 53.062477,
      "lng": -0.848516,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good3.png",
      "lat": 53.130814,
      "lng": -1.198958,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good1.png",
      "lat": 53.68222,
      "lng": -2.687525,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good2.png",
      "lat": 53.55007,
      "lng": -2.396831,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good3.png",
      "lat": 51.651096,
      "lng": -3.017772,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good1.png",
      "lat": 53.119,
      "lng": -1.106313,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good2.png",
      "lat": 53.041386,
      "lng": -1.19993,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good3.png",
      "lat": 53.37613,
      "lng": -1.05831,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good1.png",
      "lat": 53.01745,
      "lng": -1.3015,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good2.png",
      "lat": 53.061207,
      "lng": -0.810361,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good3.png",
      "lat": 53.229195,
      "lng": -4.129437,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good1.png",
      "lat": 53.229195,
      "lng": -4.129437,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good2.png",
      "lat": 54.775417,
      "lng": -1.576834,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good3.png",
      "lat": 51.532837,
      "lng": -0.472856,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good1.png",
      "lat": 52.77991,
      "lng": -2.425557,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good2.png",
      "lat": 52.975,
      "lng": -1.102984,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good3.png",
      "lat": 53.12754,
      "lng": -1.158307,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": okColor,
      "img": "good1.png",
      "lat": 53.064705,
      "lng": -0.789849,
      "issue": "The timeclocks on this site look to be preforming as they should be",
      "text": "The timeclocks on this site look to be preforming as they should be"
    },
    {
      "color": problemColor,
      "img": "badwastetemp1.png",
      "lat": 52.947018,
      "lng": -1.034465,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock2.png",
      "lat": 54.90322,
      "lng": -1.54914,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock3.png",
      "lat": 54.892,
      "lng": -1.376225,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock.png",
      "lat": 54.84275,
      "lng": -1.474417,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetemp1.png",
      "lat": 51.54761,
      "lng": -0.055243,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock3.png",
      "lat": 51.620754,
      "lng": 0.302835,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock.png",
      "lat": 51.662685,
      "lng": -3.804803,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock2.png",
      "lat": 51.620228,
      "lng": -3.944167,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock3.png",
      "lat": 51.857613,
      "lng": -4.307936,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock.png",
      "lat": 54.577496,
      "lng": -1.237501,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock2.png",
      "lat": 52.755695,
      "lng": 0.395156,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetemp1.png",
      "lat": 51.496853,
      "lng": -3.194807,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock.png",
      "lat": 51.525528,
      "lng": -0.122238,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock2.png",
      "lat": 51.489014,
      "lng": -3.179717,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock3.png",
      "lat": 54.898754,
      "lng": -1.416162,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock.png",
      "lat": 54.993645,
      "lng": -1.70641,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock2.png",
      "lat": 54.887943,
      "lng": -1.520951,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock3.png",
      "lat": 51.76227,
      "lng": -1.262213,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetemp1.png",
      "lat": 51.75776,
      "lng": -1.261797,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock2.png",
      "lat": 54.873528,
      "lng": -1.400538,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock3.png",
      "lat": 54.870556,
      "lng": -1.498722,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock.png",
      "lat": 52.41096,
      "lng": -0.742046,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock2.png",
      "lat": 50.16863,
      "lng": -5.125898,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock3.png",
      "lat": 50.842377,
      "lng": 0.464333,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetemp1.png",
      "lat": 50.867794,
      "lng": 0.252023,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock2.png",
      "lat": 51.757816,
      "lng": -1.272503,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": problemColor,
      "img": "badwastetimeclock3.png",
      "lat": 51.23376,
      "lng": -0.331673,
      "issue": "This site is consuming more than we would expect under the current weather conditions",
      "text": "This site is consuming more than we would expect under the current weather conditions"
    },
    {
      "color": warnColor,
      "img": "badclock1.png",
      "lat": 50.37544,
      "lng": -4.13794,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock2.png",
      "lat": 53.410576,
      "lng": -2.984616,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock3.png",
      "lat": 51.77133,
      "lng": -1.255402,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock1.png",
      "lat": 51.77133,
      "lng": -1.255402,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock2.png",
      "lat": 52.40488,
      "lng": -1.499245,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock3.png",
      "lat": 52.622086,
      "lng": -1.124531,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock1.png",
      "lat": 52.61823,
      "lng": -1.129408,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock2.png",
      "lat": 51.757954,
      "lng": -1.25413,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock3.png",
      "lat": 51.499443,
      "lng": -0.17052089,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock1.png",
      "lat": 51.761074,
      "lng": -1.260609,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock2.png",
      "lat": 51.758976,
      "lng": -1.253939,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock3.png",
      "lat": 52.63201,
      "lng": -1.140475,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock1.png",
      "lat": 52.9519,
      "lng": -1.18381,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock2.png",
      "lat": 51.514706,
      "lng": -0.174871,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock3.png",
      "lat": 51.424812,
      "lng": -0.566745,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock1.png",
      "lat": 53.477592,
      "lng": -2.222579,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock2.png",
      "lat": 52.63201,
      "lng": -1.140475,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock3.png",
      "lat": 52.63201,
      "lng": -1.140475,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock1.png",
      "lat": 51.532528,
      "lng": -0.271047,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock2.png",
      "lat": 54.77357,
      "lng": -1.562894,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock3.png",
      "lat": 51.755505,
      "lng": -0.244071,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock1.png",
      "lat": 51.45796,
      "lng": -2.599464,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock2.png",
      "lat": 51.45864,
      "lng": -2.57516,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock3.png",
      "lat": 51.455074,
      "lng": -2.604749,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock1.png",
      "lat": 51.453304,
      "lng": -2.631854,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock2.png",
      "lat": 51.45456,
      "lng": -2.611881,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock3.png",
      "lat": 51.453827,
      "lng": -2.613353,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock1.png",
      "lat": 51.45985,
      "lng": -2.602238,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock2.png",
      "lat": 51.46024,
      "lng": -2.606055,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock3.png",
      "lat": 53.415993,
      "lng": -2.546743,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock1.png",
      "lat": 51.13112,
      "lng": -2.987131,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    },
    {
      "color": warnColor,
      "img": "badclock2.png",
      "lat": 52.18658,
      "lng": 0.137258,
      "issue": "The timeclocks on this site look like they can be improved",
      "text": "The timeclocks on this site look like they can be improved"
    }
  ]